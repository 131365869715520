import { ReactNode } from "react";
import { Button, useRecordContext } from "react-admin";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Customer } from "../api";

export function BulkDownloadMembersButton(): ReactNode | null {
  const customer = useRecordContext<Customer>();
  if (!customer) return null;

  const extractUrl = `${import.meta.env.VITE_API_BASE_URL}/staff/customers/${customer.id}/members-bulk-export`;
  return (
    <Button
      label="customer.bulk_export_members.button"
      color="secondary"
      size="small"
      startIcon={<FileDownloadOutlinedIcon />}
      href={extractUrl}
    />
  );
}
