import { useRecordContext, useTranslate } from "react-admin";
import { MarkdownField } from "@react-admin/ra-markdown";
import { Stack, SxProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { baseAnswerSxProps } from "../themes";
import { Answer } from "../../api";
import AiSuggestionAnswerButtonDialog from "./AiSuggestionAnswerButtonDialog";
import LawyerAnswerDateTimeBlock from "./LawyerAnswerDateTimeBlock";
import FormalNoticesButtonDialog from "./FormalNoticesButtonDialog";

type LawyerAnswerProps = {
  contentSx?: SxProps;
};

export default function LawyerAnswer({ contentSx = baseAnswerSxProps }: LawyerAnswerProps): ReactNode | null {
  const translate = useTranslate();
  const record = useRecordContext<Answer>();
  if (!record) return null;

  return (
    <>
      <MarkdownField source="content" sx={contentSx} />
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <LawyerAnswerDateTimeBlock />
        <AiSuggestionAnswerButtonDialog />
        <FormalNoticesButtonDialog />
      </Stack>
      {record.status === "pending_moderation" && (
        <Stack direction="row" justifyContent="flex-end">
          <Typography variant="body2" color="red">
            {translate("question.edit.pendingValidation.label")}
          </Typography>
        </Stack>
      )}
    </>
  );
}
