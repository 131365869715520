import { HttpError } from "react-admin";
import axios from "axios";
import queryString from "query-string";
import { ErrorAlert, isErrorPayload } from "../components/ErrorAlert";

export const LOGIN_URL = `${import.meta.env.VITE_API_BASE_URL}/staff/auth/login`;
export const LOGOUT_URL = `${import.meta.env.VITE_API_BASE_URL}/staff/auth/logout`;

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  paramsSerializer(params) {
    // We use queryString to convert array filters in multiple query parameters with the same name.
    // For example: { status: ['active', 'in_test'] } => status=active&status=in_test
    return queryString.stringify(params);
  },
  // Send cookies
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error) && error.response && isErrorPayload(error.response.data)) {
      return Promise.reject(
        new HttpError(
          <ErrorAlert message={error.response.data.message} details={error.response.data.details} />,
          error.response.status,
          error.response.data,
        ),
      );
    }
    return Promise.reject(error);
  },
);

export type PageResponsePayload<T> = {
  items: T[];
  total: number;
};

export type ArrayResponsePayload<T> = {
  [key: string]: T[];
};

export async function fetchPaginatedResource<T>(
  resource: string,
  filters?: Record<string, unknown>,
  page?: number,
  perPage?: number,
): Promise<PageResponsePayload<T>> {
  const { data } = await axiosInstance.get<PageResponsePayload<T>>(resource, {
    params: { ...filters, page_size: perPage, page },
  });
  return data;
}

export async function fetchArrayResource<T>(resource: string, filters?: Record<string, unknown>): Promise<T[]> {
  const { data } = await axiosInstance.get<ArrayResponsePayload<T>>(resource, {
    params: { ...filters },
  });
  return data[resource.split("/").pop() as string];
}

export type UserInfoResponse = {
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  picture: string;
  roles: string[];
};

export async function getUserInfo(): Promise<UserInfoResponse> {
  const { data } = await axiosInstance.get<UserInfoResponse>("/staff/auth/userinfo");
  return data;
}

export type DispatchConfigLine = {
  id: number;
  law_domain_id: string;
  lawyer_id: number;
  enabled: boolean;
  percent: number;
};

export type DispatchLawyer = {
  id: number;
  name: string;
};

export type DispatchDomain = {
  id: string;
  name: string;
};

export type DispatchConfigResponse = {
  id: string;
  configs: DispatchConfigLine[];
  lawyers: DispatchLawyer[];
  domains: DispatchDomain[];
};
