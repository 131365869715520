import { ReactNode, useEffect, useState } from "react";
import {
  BooleanField,
  CheckboxGroupInput,
  Confirm,
  Datagrid,
  EditButton,
  FilterForm,
  FilterFormProps,
  FunctionField,
  IconButtonWithTooltip,
  Labeled,
  NullableBooleanInput,
  Pagination,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
  useTranslate,
  WrapperField,
} from "react-admin";
import { Chip, Grid, Stack, Tooltip, Typography } from "@mui/material";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import { DefaultError, useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Customer } from "./api";
import ColoredChip from "../../components/ColoredChip";
import EditProductDialogButton from "./product/EditProductDialogButton";
import { Product } from "./product/api";
import CreateProductDialogButton from "./product/CreateProductDialogButton";
import CreateMemberDialogButton from "../members/CreateMemberDialogButton";
import DateTimeField from "../../components/DateTimeField";
import { Member } from "../members/api";
import SubscriptionStatusChip from "../members/SubscriptionStatusChip";

import { EkieDataProvider } from "../../providers/dataProvider";
import { memberRepresentation } from "../members/format";
import { ScheduleOnboardingEmailsButton } from "./components/ScheduleOnboardingEmailsButton";
import { BulkUpdateMembersButton } from "./components/BulkUpdateMembersButton";
import { BulkDownloadMembersButton } from "./components/BulkDownloadMembersButton";
import { MemberDeleteButton } from "./components/MemberDeleteButton";
import { ROLE_CUSTOMER_SERVICE } from "../../providers/authProvider";
import { CustomerCseProfile } from "./CustomerCseProfile";

function CustomerShowTitle() {
  const record = useRecordContext<Customer>();
  if (!record) return null;
  return <span>{record.name}</span>;
}

function OnboardingEmailStatusIcon() {
  const translate = useTranslate();
  const record = useRecordContext<Member>();
  if (!record) return null;
  if (record.onboarding_email_status === "scheduled") {
    return (
      <Tooltip title={<DateTimeField source="onboarding_email_status_at" showTime />} arrow>
        <Chip label={translate("customer.schedule_onboarding_email.chip.scheduled")} size="small" />
      </Tooltip>
    );
  }
  if (record.onboarding_email_status === "sent") {
    return (
      <Tooltip title={<DateTimeField source="onboarding_email_status_at" showTime />} arrow>
        <Chip label={translate("customer.schedule_onboarding_email.chip.sent")} size="small" />
      </Tooltip>
    );
  }
  return null;
}

function OnboardingEmailActions() {
  const dataProvider = useDataProvider<EkieDataProvider>();
  const member = useRecordContext<Member>();
  const refresh = useRefresh();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const { mutate, isPending } = useMutation<void, DefaultError, void>({
    mutationFn: () => dataProvider.sendOnboardingEmail(member!.id),
    onSuccess: () => {
      refresh();
      notify("customer.schedule_onboarding_email.success", { type: "success" });
    },
    onError: (error: Error) => {
      notify(error.message, { type: "error" });
    },
  });
  if (!member) return null;
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    e.stopPropagation();
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButtonWithTooltip
          onClick={handleClick}
          size="small"
          label="customer.schedule_onboarding_email.tooltip"
          disabled={!member.can_receive_onboarding_email}
        >
          <ForwardToInboxOutlinedIcon />
        </IconButtonWithTooltip>
        <OnboardingEmailStatusIcon />
      </Stack>
      <Confirm
        isOpen={open}
        loading={isPending}
        title="customer.schedule_onboarding_email.dialog.title"
        content="customer.schedule_onboarding_email.dialog.content"
        translateOptions={{ name: memberRepresentation(member) }}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
}

function PersistentFilterForm(props: FilterFormProps) {
  const { filterValues } = useListContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const query = queryString.stringify(filterValues, { arrayFormat: "bracket" });
    navigate(
      {
        pathname: location.pathname,
        search: query.length ? `?${query}` : "",
      },
      { replace: true },
    );
  }, [filterValues, navigate, location.pathname]);

  return <FilterForm {...props} />;
}

export function CustomerShow(): ReactNode {
  const translate = useTranslate();
  const { permissions } = usePermissions<string[]>();
  const location = useLocation();

  const query = queryString.parse(location.search, { arrayFormat: "bracket", parseBooleans: true });

  return (
    <Show
      title={<CustomerShowTitle />}
      actions={
        permissions?.includes(ROLE_CUSTOMER_SERVICE) && (
          <TopToolbar>
            <EditButton />
          </TopToolbar>
        )
      }
    >
      <SimpleShowLayout>
        <Grid container spacing={{ xs: 2, lg: 8 }}>
          <Grid item xs={12} lg={6}>
            <Stack spacing={1} width={400}>
              <Typography component="h2" variant="h5">
                {translate("customer.show.title")}
              </Typography>
              <Labeled>
                <TextField source="name" />
              </Labeled>
              <Labeled>
                <TextField source="code" />
              </Labeled>
              <Labeled>
                <TextField source="type" />
              </Labeled>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack spacing={2} sx={{ width: 1 }}>
              <Stack direction="row" gap={2}>
                <Typography component="h2" variant="h5">
                  {translate("customer.edit.section.product.title")}
                </Typography>
                {permissions?.includes(ROLE_CUSTOMER_SERVICE) && <CreateProductDialogButton />}
              </Stack>
              <ReferenceManyField reference="staff/products" target="customer_id">
                <Datagrid bulkActionButtons={false} hover>
                  <TextField source="name" sortable={false} label="customer.edit.section.product.column.name.title" />
                  <FunctionField<Product>
                    sortable={false}
                    label="customer.edit.section.product.column.collaborator_type.title"
                    render={(product) => {
                      if (!product.collaborator_type) return null;
                      return (
                        <ColoredChip
                          value={product.collaborator_type}
                          colorMapping={{ cse: "warning", employee: "success" }}
                          labelPrefix="customer.edit.section.product.column.collaborator_type"
                        />
                      );
                    }}
                  />
                  <EditProductDialogButton />
                </Datagrid>
              </ReferenceManyField>
              <CustomerCseProfile />
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack direction="row" gap={2}>
              <Typography component="h2" variant="h5">
                <span>{translate("member.title.list")}</span>
              </Typography>
              {permissions?.includes(ROLE_CUSTOMER_SERVICE) && (
                <>
                  <CreateMemberDialogButton />
                  <ScheduleOnboardingEmailsButton />
                  <BulkUpdateMembersButton />
                  <BulkDownloadMembersButton />
                </>
              )}
            </Stack>
            <ReferenceManyField
              pagination={<Pagination />}
              perPage={5}
              target="customer_id"
              reference="staff/members"
              filter={query}
            >
              <PersistentFilterForm
                filters={[
                  <TextInput source="search" label="member.search" alwaysOn />,
                  <NullableBooleanInput source="is_cse_member" label="member.cse_member" alwaysOn />,
                  <NullableBooleanInput source="is_admin" label="member.admin" alwaysOn />,
                  <CheckboxGroupInput
                    alwaysOn
                    label="member.status"
                    source="subscription_status"
                    choices={[
                      { name: "member.status.active", id: "active" },
                      { name: "member.status.active_terminated", id: "active_terminated" },
                      { name: "member.status.terminated", id: "terminated" },
                      { name: "member.status.new", id: "new" },
                    ]}
                  />,
                ]}
              />
              <Datagrid bulkActionButtons={false}>
                <TextField source="full_name" sortable={false} label="member.fullName" />
                <TextField source="email" sortable={false} label="member.email" />
                <BooleanField source="is_cse_member" sortable={false} label="member.cse_member" />{" "}
                <BooleanField source="is_admin" sortable={false} label="member.admin" />
                <WrapperField label="customer.schedule_onboarding_email.label">
                  <OnboardingEmailActions />
                </WrapperField>
                <SubscriptionStatusChip />
                <MemberDeleteButton />
              </Datagrid>
            </ReferenceManyField>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
