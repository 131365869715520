import { ReactNode } from "react";
import { Button, useRecordContext, useTranslate } from "react-admin";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { FormalNotice } from "../../api";

export function FormalNoticeDownloadButton(): ReactNode | null {
  const formalNotice = useRecordContext<FormalNotice>();
  const translate = useTranslate();

  if (!formalNotice) return null;

  const extractUrl = `${import.meta.env.VITE_API_BASE_URL}/staff/formal-notices/${formalNotice.id}/download`;
  return (
    <Button
      label={translate("question.edit.formalNotice.download.button.label")}
      color="secondary"
      variant="contained"
      size="medium"
      startIcon={<FileDownloadOutlinedIcon />}
      href={extractUrl}
    />
  );
}
