import { DateField, Labeled, Link, TextField, useRecordContext, useTranslate } from "react-admin";
import { Box, Grid, LinearProgress, LinearProgressProps, Stack, Typography } from "@mui/material";
import { ShowInDialogButton } from "@react-admin/ra-form-layout";
import { Customer } from "./api";

function CseProfileProgressBar({ value, ...props }: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={value}
          {...props}
          sx={{ borderRadius: 5, height: 10 }}
          color="info"
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export function CustomerCseProfile() {
  const customer = useRecordContext<Customer>();
  const translate = useTranslate();
  if (!customer?.cse_profile) return null;
  return (
    <Stack direction="column" gap={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography component="h2" variant="h5">
          {translate("customer.cse_profile")}
        </Typography>
        <ShowInDialogButton
          fullWidth
          maxWidth="sm"
          label="customer.cse_profile.show"
          ButtonProps={{ color: "secondary" }}
          title="customer.cse_profile"
        >
          <Grid container spacing={1} padding={2} paddingTop={0}>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.siren">
                <TextField source="cse_profile.siren" emptyText="common.field.missingValue" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.company_name">
                <TextField
                  source="cse_profile.company_name"
                  label="customer.cse_profile.company_name"
                  emptyText="common.field.missingValue"
                />
              </Labeled>
            </Grid>
            <Grid item xs={12}>
              <Labeled label="customer.cse_profile.convention_collective">
                {customer.cse_profile.convention_collective?.url ? (
                  <Link to={customer.cse_profile.convention_collective.url} target="_blank" rel="noreferrer">
                    <TextField source="cse_profile.convention_collective.title" />
                  </Link>
                ) : (
                  <TextField source="cse_profile.convention_collective.title" emptyText="common.field.missingValue" />
                )}
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.employees_count">
                <TextField source="cse_profile.employees_count" emptyText="common.field.missingValue" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.cse_members_count">
                <TextField source="cse_profile.cse_members_count" emptyText="common.field.missingValue" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.union_delegate_count">
                <TextField source="cse_profile.union_delegate_count" emptyText="common.field.missingValue" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.union_section_representatives_count">
                <TextField
                  source="cse_profile.union_section_representatives_count"
                  emptyText="common.field.missingValue"
                />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.last_election_date">
                <DateField source="cse_profile.last_election_date" emptyText="common.field.missingValue" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.mandate_duration_year">
                <TextField source="cse_profile.mandate_duration_year" emptyText="common.field.missingValue" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.next_election_date">
                <DateField source="cse_profile.next_election_date" emptyText="common.field.missingValue" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.cse_documents_count">
                <TextField source="cse_profile.cse_documents_count" emptyText="common.field.missingValue" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.company_documents_count">
                <TextField source="cse_profile.company_documents_count" emptyText="common.field.missingValue" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="customer.cse_profile.internal_rules_documents_count">
                <TextField source="cse_profile.internal_rules_documents_count" emptyText="common.field.missingValue" />
              </Labeled>
            </Grid>
          </Grid>
        </ShowInDialogButton>
      </Stack>
      <CseProfileProgressBar value={customer.cse_profile.completion_percentage} />
    </Stack>
  );
}
