import { alpha, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { ShowDialog } from "@react-admin/ra-form-layout";
import { RecordContextProvider, SimpleShowLayout, useGetList, useRecordContext, useTranslate } from "react-admin";
import React, { ReactNode, useState } from "react";
import { MarkdownField } from "@react-admin/ra-markdown";
import { grey } from "@mui/material/colors";
import EmailOutlinedIcon from "@mui/icons-material/Email";
import { baseAnswerSxProps } from "../themes";
import { Answer, FormalNotice } from "../../api";
import { FormalNoticeDownloadButton } from "./FormalNoticeDownloadButton";

export default function FormalNoticesButtonDialog(): ReactNode | null {
  const answer = useRecordContext<Answer>();
  const { data: formalNotices } = useGetList<FormalNotice>("staff/formal-notices", {
    filter: { answer_id: answer?.id },
  });
  const translate = useTranslate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const theme = useTheme();

  if (!answer || !formalNotices || formalNotices.length === 0) return null;

  return (
    <>
      <Tooltip title={translate("question.edit.formalNotice.button.tooltip")}>
        <IconButton size="small" onClick={() => setIsDialogOpen(true)}>
          <EmailOutlinedIcon fontSize="small" color="secondary" />
        </IconButton>
      </Tooltip>
      <ShowDialog
        title={translate("question.edit.formalNotice.dialog.title")}
        isOpen={isDialogOpen}
        close={() => setIsDialogOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <SimpleShowLayout>
          <RecordContextProvider value={formalNotices[0]}>
            <Stack alignItems="end">
              <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                <Stack direction="column" width="50%">
                  <MarkdownField
                    source="ai_generated_body"
                    sx={{
                      ...baseAnswerSxProps,
                      backgroundColor: alpha(theme.palette.secondary.light, 0.5),
                    }}
                    label=""
                  />
                  <Typography variant="body2" sx={{ color: grey[600] }} align="right">
                    <span>{translate("question.edit.formalNotice.aiGenerated.label")}</span>
                  </Typography>
                </Stack>
                <Stack direction="column" width="50%">
                  <MarkdownField source="body" sx={baseAnswerSxProps} />
                  <Typography variant="body2" sx={{ color: grey[600] }} align="right">
                    <span>{translate("question.edit.formalNotice.lawyerProposed.label")}</span>
                  </Typography>
                </Stack>
              </Stack>
              <div>
                <FormalNoticeDownloadButton />
              </div>
            </Stack>
          </RecordContextProvider>
        </SimpleShowLayout>
      </ShowDialog>
    </>
  );
}
