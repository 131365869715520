import { axiosInstance } from "../../providers/api";

export type Customer = {
  id: number;
  name: string;
  code: string;
  type: "employer" | "distributor";
  source_label: string;
  cse_profile?: CseProfile;
};

export type CseProfile = {
  id: number;
  updated_at: string;
  completion_percentage: number;
  siren?: string;
  company_name?: string;
  employees_count?: number;
  cse_members_count?: number;
  convention_collective?: {
    title: string;
    url: string;
  };
  mandate_duration_year?: number;
  last_election_date?: string;
  next_election_date?: string;
  union_delegate_count?: number;
  union_section_representatives_count?: number;
  cse_documents_count: number;
  cse_documents_not_applicable?: boolean;
  company_documents_count: number;
  company_documents_not_applicable?: boolean;
  internal_rules_documents_count: number;
  internal_rules_documents_not_applicable?: boolean;
};

export type ScheduleOnboardingEmailsParams = {
  id: number;
  at: string;
};

export type ScheduleOnboardingEmailsResponse = { scheduled_emails_count: number };

export type BulkUpdateMembersParams = {
  id: number;
  customer_members_file: {
    rawFile: File;
  };
};

export type BulkUpdateMembersResponse = {
  deleted_count: number;
  updated_count: number;
  created_count: number;
  deactivated_count: number;
};

export const customerProviderMethods = {
  async scheduleOnboardingEmails({
    id,
    at,
  }: ScheduleOnboardingEmailsParams): Promise<ScheduleOnboardingEmailsResponse> {
    const { data } = await axiosInstance.post<ScheduleOnboardingEmailsResponse>(
      `/staff/customers/${id}/schedule-onboarding-emails`,
      { at },
    );
    return data;
  },
  async bulkUpdateMembers({ id, customer_members_file }: BulkUpdateMembersParams): Promise<BulkUpdateMembersResponse> {
    const formData = new FormData();
    formData.append("file", customer_members_file.rawFile);
    const { data } = await axiosInstance.post<BulkUpdateMembersResponse>(
      `/staff/customers/${id}/members-bulk-update`,
      formData,
    );
    return data;
  },
};

export type CustomerDataProvider = typeof customerProviderMethods;
