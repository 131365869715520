import React from "react";
import { EditDialog } from "@react-admin/ra-form-layout";
import {
  DateTimeInput,
  required,
  SaveButton,
  SaveContextProvider,
  SimpleForm,
  Toolbar,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import { useMutation } from "@tanstack/react-query";
import ActionCheck from "@mui/icons-material/CheckCircle";
import { LawyerAppointment } from "../../lawyer/api";
import { EkieDataProvider } from "../../../providers/dataProvider";

export type EditConsultationDialogPros = {
  isOpen: boolean;
  appointment_id: string;
  title: string;
  onClose: () => void;
};

export default function EditConsultationDialog({ onClose, isOpen, title, appointment_id }: EditConsultationDialogPros) {
  const dataProvider = useDataProvider<EkieDataProvider>();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const { mutate: reschedule, isPending } = useMutation({
    mutationFn: (appointment: LawyerAppointment) => {
      return dataProvider.rescheduleAppointment(appointment.id, new Date(appointment.start_at).toISOString());
    },
    onSuccess: () => {
      refresh();
      onClose();
    },
    onError: (error: Error) => {
      notify(error.message, { type: "error" });
      onClose();
    },
  });
  return (
    <EditDialog
      fullWidth
      isOpen={isOpen}
      close={onClose}
      resource="staff/appointments"
      id={appointment_id}
      title={translate("lawyer.appointment.edit.title", { title })}
    >
      <SaveContextProvider value={{ save: reschedule, saving: isPending }}>
        <SimpleForm
          toolbar={
            <Toolbar sx={{ display: "flex", justifyContent: "end" }}>
              <SaveButton label="lawyer.appointment.edit.save" color="error" variant="text" icon={<ActionCheck />} />
            </Toolbar>
          }
        >
          <DateTimeInput source="start_at" label="lawyer.appointment.edit.start_at" validate={required()} />
        </SimpleForm>
      </SaveContextProvider>
    </EditDialog>
  );
}
