import { AuthProvider } from "react-admin";
import { getUserInfo, LOGOUT_URL, LOGIN_URL } from "./api";

export const ROLE_CUSTOMER_SERVICE = "Customer Service";
export const ROLE_OPERATIONS = "Operations";

/* Setting window.location.href is not synchronous, this means that if
 * another request receive an error a few ms after, the checkError
 * handler will be called (again) before the page reload occurred.
 *
 * This boolean is here to prevent this behavior.
 */
let requestedLogin = false;

const baseAuthProvider: AuthProvider = {
  login: async () => {},
  logout: async () => {
    window.location.href = LOGOUT_URL;
  },
  checkError: (error) => {
    if (error.status === 401 && !requestedLogin) {
      window.location.href = `${LOGIN_URL}?redirect_uri=${encodeURIComponent(window.location.href)}`;
      requestedLogin = true;
    }
    return Promise.resolve();
  },
  checkAuth: async () => {},
  getPermissions: async () => {
    const { roles } = await getUserInfo();
    return roles;
  },
  async getIdentity() {
    const userInfo = await getUserInfo();
    return {
      id: userInfo.email,
      fullName: userInfo.full_name,
      avatar: userInfo.picture,
    };
  },
};

export default baseAuthProvider;
