import { Admin, CheckForApplicationUpdate, CustomRoutes, defaultTheme, Layout, Resource } from "react-admin";
import React, { ReactNode } from "react";
import { AppLocationContext } from "@react-admin/ra-navigation";
import { Route, RouterProvider, createBrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { fr } from "date-fns/locale";
import dataProvider from "./providers/dataProvider";
import authProvider from "./providers/authProvider";
import i18nProvider from "./i18n/i18nProvider";
import LawyerList from "./features/lawyer/LawyerList";
import LawyerEdit from "./features/lawyer/LawyerEdit";
import { MemberList } from "./features/members/MemberList";
import MemberEdit from "./features/members/MemberEdit";
import AssignableQuestionsList from "./features/questions/list/AssignableQuestionsList";
import Menu from "./Menu";
import LawyerCalendar from "./features/lawyer/calendar/LawyerCalendar";
import QuestionEdit from "./features/questions/edit/QuestionEdit";
import { memberRepresentation } from "./features/members/format";
import UnansweredQuestionsList from "./features/questions/list/UnansweredQuestionsList";
import AnsweredQuestionsList from "./features/questions/list/AnsweredQuestionsList";
import AnswerToValidateQuestionsList from "./features/questions/list/AnswerToValidateQuestionsList";
import ConsultationList from "./features/consultation/ConsultationList";
import ConsultationEdit from "./features/consultation/ConsultationEdit";
import MemberQuestionsList from "./features/questions/list/MemberQuestionsList";
import { CustomerList } from "./features/customer/CustomerList";
import { CustomerEdit } from "./features/customer/CustomerEdit";
import CustomerCreate from "./features/customer/CustomerCreate";
import DispatchConfigEdit from "./features/lawyer/dispatch/DispatchConfigEdit";
import { answerRepresentation } from "./features/questions/format";
import { CustomerShow } from "./features/customer/CustomerShow";

export const theme = {
  ...defaultTheme,
  palette: {
    primary: { main: "#000000" },
    secondary: { main: "#9022FF", light: "#DAB5FF" },
    warning: { main: "#FF993A", contrastText: "#FFFFFF" },
    error: { main: "#FF3A3A" },
    success: { main: "#0DB952", contrastText: "#FFFFFF" },
    tonalOffset: 0.2,
  },
};

export function AppLayout({ children }: { children: ReactNode }) {
  return (
    <AppLocationContext>
      <Layout menu={Menu}>
        {children}
        <CheckForApplicationUpdate />
      </Layout>
    </AppLocationContext>
  );
}

export default function App() {
  const router = createBrowserRouter([
    {
      path: "*",
      element: (
        <Admin
          theme={theme}
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          layout={AppLayout}
          requireAuth
          disableTelemetry
        >
          <Resource
            name="staff/members"
            options={{ label: "member.label" }}
            list={MemberList}
            edit={MemberEdit}
            recordRepresentation={memberRepresentation}
          />
          <Resource
            name="staff/lawyers"
            list={LawyerList}
            edit={LawyerEdit}
            recordRepresentation={(record) => record.full_name}
          />
          <Resource name="lawyer-events" list={<LawyerCalendar />} />
          <Resource
            name="staff/questions/assignable"
            list={AssignableQuestionsList}
            options={{ label: "question.label" }}
          />
          <Resource
            name="staff/questions/unanswered"
            list={UnansweredQuestionsList}
            options={{ label: "question.label" }}
          />
          <Resource
            name="staff/questions/answer-to-validate"
            list={AnswerToValidateQuestionsList}
            options={{ label: "question.label" }}
          />
          <Resource
            name="staff/questions/answered"
            list={AnsweredQuestionsList}
            options={{ label: "question.label" }}
          />
          <Resource
            name="staff/questions"
            list={MemberQuestionsList}
            edit={QuestionEdit}
            options={{ label: "question.label" }}
          />
          <Resource name="staff/consultations" list={ConsultationList} edit={ConsultationEdit} />
          <Resource
            name="staff/answers"
            recordRepresentation={answerRepresentation}
            options={{ label: "answer.label" }}
          />
          <Resource name="staff/languages" />
          <Resource name="staff/law-domains" />
          <Resource name="staff/products" options={{ label: "product.label" }} />
          <Resource
            name="staff/customers"
            options={{ label: "customer.list.title" }}
            list={CustomerList}
            edit={CustomerEdit}
            show={CustomerShow}
            create={CustomerCreate}
          />
          <Resource name="staff/dispatch-config" />
          <Resource name="staff/law-domains" />
          <CustomRoutes>
            <Route path="staff/dispatch-config/edit" element={<DispatchConfigEdit />} />
          </CustomRoutes>
        </Admin>
      ),
    },
  ]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <RouterProvider router={router} />
    </LocalizationProvider>
  );
}
