import {
  AutocompleteInput,
  BooleanInput,
  ChipField,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  email,
  FunctionField,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  usePermissions,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import MemberAside from "./MemberAside";
import DateTimeField from "../../components/DateTimeField";
import PhoneInput from "../../components/PhoneInput";
import ResetPasswordButton from "./ResetPasswordButton";
import { Member, Subscription } from "./api";
import { EkieDataProvider } from "../../providers/dataProvider";
import SubscriptionStatusChip from "./SubscriptionStatusChip";
import { memberRepresentation } from "./format";
import { Product } from "../customer/product/api";
import ActionDialogButton from "../../components/ActionDialogButton";
import { ROLE_CUSTOMER_SERVICE, ROLE_OPERATIONS } from "../../providers/authProvider";
import MemberCancelButton from "./MemberCancelButton";
import { ConfirmButton } from "../../components/ConfirmButton";

function MemberTitle() {
  const record = useRecordContext<Member>();
  if (!record) return null;
  return <span>{memberRepresentation(record)}</span>;
}

type MemberSubscribeForm = {
  product_id: number;
};

function MemberBottomToolbar() {
  const { permissions } = usePermissions<string[]>();
  const record = useRecordContext<Member>();
  const dataProvider = useDataProvider<EkieDataProvider>();
  if (!record) return null;
  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <Stack direction="row" spacing={1}>
        <SaveButton />
        {permissions?.includes(ROLE_CUSTOMER_SERVICE) && record?.can_be_deleted && (
          <DeleteWithConfirmButton
            translateOptions={{ name: memberRepresentation(record) }}
            record={record}
            confirmColor="warning"
            confirmContent="member.delete.content"
            confirmTitle="member.delete.title"
            variant="contained"
            size="medium"
            mutationMode="pessimistic"
          />
        )}
        {permissions?.includes(ROLE_CUSTOMER_SERVICE) && record?.can_reset_password && <ResetPasswordButton />}
        {permissions?.includes(ROLE_OPERATIONS) && record?.can_be_anonymized && (
          <ConfirmButton
            buttonProps={{
              startIcon: <DeleteForeverOutlinedIcon />,
              color: "error",
              size: "medium",
              variant: "outlined",
              label: "member.anonymize",
            }}
            dataProviderAction={() => dataProvider.anonymizeMemberData(record.id)}
            confirmProps={{
              confirmColor: "warning",
              title: "member.anonymize.title",
              content: "member.anonymize.content",
              translateOptions: { name: memberRepresentation(record) },
              confirm: "member.anonymize",
            }}
            successMessage="member.anonymize.success"
          />
        )}
      </Stack>
    </Toolbar>
  );
}

export default function MemberEdit() {
  const translate = useTranslate();
  const { permissions } = usePermissions<string[]>();
  const dataProvider = useDataProvider<EkieDataProvider>();
  return (
    <Edit mutationMode="pessimistic" title={<MemberTitle />} aside={<MemberAside />}>
      <SimpleForm toolbar={<MemberBottomToolbar />}>
        <Grid container maxWidth={{ xs: "100%", lg: 1200 }} columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12}>
            <Stack spacing={{ xs: 2, lg: 2 }}>
              <Typography component="h2" variant="h5">
                {translate("member.section.contact")}
              </Typography>
              <Stack direction={{ xs: "column", lg: "row" }} mt={1} spacing={{ xs: 0, lg: 2 }}>
                <TextInput fullWidth source="last_name" label="member.lastName" />
                <TextInput fullWidth source="first_name" label="member.firstName" />
              </Stack>
              <Stack direction={{ xs: "column", lg: "row" }} mt={1} spacing={{ xs: 0, lg: 2 }}>
                <TextInput source="email" label="member.email" validate={[required(), email()]} />
                <TextInput source="secondary_email" label="member.secondary_email" validate={email()} />
              </Stack>
              <Stack direction={{ xs: "column", lg: "row" }} mt={1} spacing={{ xs: 0, lg: 2 }}>
                <PhoneInput name="phone_cellphone" label="member.phone_cellphone" />
                <PhoneInput name="phone_landline" label="member.phone_landline" />
              </Stack>
              <Stack direction={{ xs: "column", lg: "row" }} mt={1} spacing={{ xs: 0, lg: 2 }}>
                <BooleanInput source="is_admin" label="member.isAdmin" />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={{ xs: 2, lg: 2 }}>
              <FunctionField
                render={(record) => {
                  return (
                    <Stack alignItems="center" direction="row" gap={2}>
                      <Typography component="h2" variant="h5">
                        <span>{translate("member.section.subscriptions")}</span>
                      </Typography>
                      <SubscriptionStatusChip />
                      {permissions?.includes(ROLE_CUSTOMER_SERVICE) && record?.can_be_subscribed && (
                        <ActionDialogButton<MemberSubscribeForm, MemberSubscribeForm>
                          dialogTitle="member.subscribe.dialog.title"
                          buttonProps={{
                            label: "member.subscribe.button.label",
                            color: "success",
                            variant: "outlined",
                            size: "small",
                          }}
                          dataProviderAction={({ product_id }) => dataProvider.subscribeMember(record.id, product_id)}
                          paramTransformer={(r) => r}
                        >
                          <Grid sx={{ width: 1 }}>
                            <ReferenceInput source="product_id" reference="staff/products">
                              <AutocompleteInput label="subscription.product" validate={required()} />
                            </ReferenceInput>
                          </Grid>
                        </ActionDialogButton>
                      )}
                      {permissions?.includes(ROLE_CUSTOMER_SERVICE) && record?.can_be_cancelled && (
                        <MemberCancelButton />
                      )}
                    </Stack>
                  );
                }}
              />
              <ReferenceArrayField reference="staff/subscriptions" source="subscription_ids">
                <Datagrid bulkActionButtons={false} rowClick={false}>
                  <DateTimeField showTime source="start_at" sortable={false} label="subscription.start_date" />
                  <DateTimeField showTime source="end_at" sortable={false} label="subscription.end_date" />
                  <ReferenceField<Subscription, Product>
                    sortable={false}
                    source="product_id"
                    reference="staff/products"
                    link={(product) => `/staff/customers/${product.customer_id}/show`}
                    label="member.product"
                  />
                  <ChipField source="status" sortable={false} label="subscription.status" />
                </Datagrid>
              </ReferenceArrayField>
            </Stack>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
}
